import { useLoaderData, Link, useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref as refDatabase, get } from 'firebase/database';
import { getStorage, ref as refStorage, getDownloadURL } from 'firebase/storage';
import 'firebase/compat/database';
import firebaseConfig from '../rules/firebaseConfig';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAuth } from '../../auth/context/AuthContext';



const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);

async function getImage(location) {
  if (!storage) {
    throw Error('Storage is not initialized');
  }
  const imageURL = await getDownloadURL(refStorage(storage, `organization-img/${location}` + '.jpg'));
  return imageURL;
}

export default function Organizations() {
  const { t } = useTranslation();
  const organizations = useLoaderData();
  const [images, setImages] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { user } = UserAuth();

  useEffect(() => {
    const fetchImages = async () => {
      const imageUrls = {};
      for (const organization of organizations) {
        const imageUrl = await getImage(organization.id); // Ensure getImage is defined
        imageUrls[organization.id] = imageUrl;
      }
      setImages(imageUrls);
    };
    fetchImages();
  }, [organizations]);


  const navigate = useNavigate();

  const handleJoin = (organization, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user) {
      setShowModal(true);
    } else {
      // Code to join the organization
      navigate(organization.id.toString());
    }
   };

  function Modal({ show, onClose }) {
    if (!show) {
      return null;
    } else {
      console.log(showModal)
    }

    return (
      <div className="modal show" style={{opacity: 1, visibility: 'visible', zIndex: 1}}>
        <div className="modal-content">
          <span className="close" onClick={onClose}>&times;</span>
          <h2>{t('account_required')} {t('organizations')}</h2>
          <Link to="/signup">
            <button className="btn btn-outline-dark btn-lg Download-button">{t('signup')} </button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={`row row-cols-1 row-cols-md-2`}>
      {organizations.map((organization) => (
        <div className='col-md-6 col-lg-4' key={organization.id}>
          <div className="card">
            <img src={images[organization.id]} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">{organization.title}</h5>
              <p className="card-context">{organization.description}</p>
              <Link to={organization.id.toString()} key={organization.id}>
              <button 
              type="button" 
              className="btn btn-outline-dark btn-lg Download-button"
              onClick={(e) => handleJoin(organization, e)}
              >
              <i className="fa-regular fa-address-card"></i> {t('join_organization')}
              </button>
              </Link>
            </div>
          </div>
        </div>
      ))}
      <Modal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
 }

// data loader
export const organizationsLoader = async () => {
  const organizationsRef = refDatabase(database, 'organizations');
  const snapshot = await get(organizationsRef);
 
  if (!snapshot.exists()) {
    throw Error('Could not fetch the list of organizations');
  }
 
  const organizationsData = snapshot.val();
  // Convert the object into an array if needed
  const organizationsArray = Object.keys(organizationsData).map(key => ({
    id: key,
    ...organizationsData[key],
  }));
 
  return organizationsArray;
 };

