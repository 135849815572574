import { doc, collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../../rules/firebaseConfig'; // Assuming firebaseConfig is in the correct path

export const shirtsLoader = async () => {
 const productsCollection = collection(firestore, 'products');
 const shirtsDoc = doc(productsCollection, 'shirts');
 const itemsCollection = collection(shirtsDoc, 'items');

 try {
  const querySnapshot = await getDocs(itemsCollection);

  if (querySnapshot.empty) {
    throw Error('Could not fetch the list of shirts');
  }

  const shirtsArray = querySnapshot.docs.map(doc => ({
    id: doc.id,
    imageUrl: doc.data().imageUrl,
    title: doc.data().title,
    price: doc.data().price,
  }));

  return shirtsArray;
 } catch (error) {
  console.error('Error fetching shirts:', error);
  throw error;
 }
};