// Navbar.js
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/white-logo.png";
import LanguageSwitcher from "./LanguageSwitcher";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { UserAuth } from '../auth/context/AuthContext';


export default function Navbar() {
 const { t } = useTranslation();
 const { user } = UserAuth();


  return (
    <nav className="navbar navbar-expand-lg navbar-light show nav">
      <NavLink to="/" className="nav-brand">
        <img src={logo} width="100" alt="" />
      </NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item active eng">
            <NavLink to="/" className="nav-link">
              {t('home')}
            </NavLink>
          </li>
          <li className="nav-item eng">
            <NavLink to="/help" className="nav-link">
              {t('help')}
            </NavLink>
          </li>
           
          {!user && (
            <li className="nav-item eng">
              <NavLink to="/signup" className="nav-link">
                {t('signup')}
              </NavLink>
            </li>
          )}

          {user && (
            <li className="nav-item eng">
              <NavLink to="/account" className="nav-link">
                {t('account')}
              </NavLink>
            </li>
          )}
          
          <li className="nav-item lang-switcher">
           <button className="btn btn-dark" style={{
            display:'inline', 
            width:'4rem', 
            margin:'0', 
            height:'3.5rem',
            borderRadius:'10px'
            }}>
              <i className="fa-solid fa-cart-shopping fa-lg"></i>
            </button>
            <LanguageSwitcher />
          </li>
        </ul>
      </div>
    </nav>
  );
}
