import { useTranslation } from "react-i18next"
import { NavLink, Outlet } from "react-router-dom"

export default function HelpLayout() {
  const { t } = useTranslation();
  return (
  <div>
    <div className="layout" style={{display:'flex'}} >
      <h1 style={{marginTop:'1.2rem', display:'inline', borderBottomStyle:'solid'}}>Website Help</h1>
      <nav className="help-link">
        <NavLink className='nav-link' to="faq">{t('faq_btn')}</NavLink>
        <NavLink className='nav-link' to="contact">{t('contact_btn')}</NavLink>
      </nav>
      </div>
      <Outlet />
  </div>

  )
}
