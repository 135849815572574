// firebaseConfig.js
import 'firebase/compat/database';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDkLAuUaju3OU15knInwcrD69tS-kDSCFg",
  authDomain: "account-management-19696.firebaseapp.com",
  databaseURL: "https://account-management-19696-default-rtdb.firebaseio.com",
  projectId: "account-management-19696",
  storageBucket: "account-management-19696.appspot.com",
  messagingSenderId: "92724941915",
  appId: "1:92724941915:web:3aa0224d4eb1ee4c799b7e",
  measurementId: "G-60JR00KKHK"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app)
const firestore = getFirestore(app);

export { firestore };
export { db };
export const auth = getAuth(app);
export default firebaseConfig;