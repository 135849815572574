import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from './context/AuthContext';
import { useTranslation } from 'react-i18next';
import { signInValidation } from '../pages/rules/AuthRules';

const Signin = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const { signIn } = UserAuth();


  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = signInValidation(email, password);
  
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    setErrors([]);
    try {
      await signIn(email, password);
      navigate('/account');
    } catch (e) {
      setErrors([e.message]);
      console.log(e.message);
    }
  };  

  return (
    <div className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>
      <div className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>
        <h1 className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>{t('signin')}</h1>
        <p>
        {t('account_!exists')}{' '}
          <Link to='/signup'>
            {t('signup')}
          </Link>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label style={{display:'inline-block'}}>{t('email')}</label>
          <input onChange={(e) => setEmail(e.target.value)}/>
        </div>
        <div>
          <label style={{display:'inline-block'}}>{t('password')}</label>
          <input onChange={(e) => setPassword(e.target.value)}/>
        </div>
        {errors.length > 0 && (
          <div>
            {errors.map((error, index) => (
              <p key={index} style={{ color: 'red' }}>
                {error}
              </p>
            ))}
          </div>
        )}
        <button className='submit'>
          {t('signin')}
        </button>
      </form>
    </div>
  );
};

export default Signin;