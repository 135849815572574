import { Outlet, ScrollRestoration } from "react-router-dom";
import Navbar from './Navbar';
import Breadcrumbs from "../components/Breadcrumbs";


export default function RootLayout() {
  return (

    <div className="home">
    <div className="container-fluid">
    <header>
     <Navbar />
    </header>
    <Breadcrumbs />
      <ScrollRestoration />
      <main>
        <Outlet />
      </main>
    </div>
    </div>
  )
}
