import {
  createBrowserRouter, 
  createRoutesFromElements,
  Route, 
  RouterProvider
} from 'react-router-dom'

// pages
import Home from './pages/Home'
import Faq from './pages/help/Faq'
import Contact from './pages/help/Contact'
import NotFound from './pages/NotFound'
// Events
import Events, { eventsLoader } from './pages/events/Events'
import EventDetails, { eventDetailsLoader } from "./pages/events/EventDetails"
import EventsError from './pages/events/EventsError'
// Organizations
import Organizations, { organizationsLoader } from './pages/organizations/Organizations'
import OrganizationDetails, { organizationDetailsLoader } from "./pages/organizations/OrganizationDetails"
import OrganizationsError from './pages/organizations/OrganizationsError'
// layouts
import RootLayout from './layouts/RootLayout'
import HelpLayout from './layouts/HelpLayout'
import EventsLayout from './layouts/EventsLayout'
import OrganizationsLayout from './layouts/OrganizationsLayout'
//authentication
import Signin from './auth/Signin';
import Signup from './auth/Signup';
import Account from './auth/Account';
import { AuthContextProvider } from './auth/context/AuthContext';
import ProtectedRoute from './auth/ProtectedRoute';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="/signin" element={<AuthContextProvider><Signin /></AuthContextProvider>} />
      <Route path="/signup" element={<AuthContextProvider><Signup /></AuthContextProvider>} />
      <Route
        path="/account"
        element={
          <AuthContextProvider>
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          </AuthContextProvider>
        }
      />
      <Route path="help" element={<HelpLayout />}>
        <Route path="faq" element={<Faq />} />
        <Route path="contact" element={<Contact/>}/>
      </Route>
      <Route path="events" element={<EventsLayout />} errorElement={<EventsError />}>
        <Route 
          index 
          element={<Events />} 
          loader={eventsLoader}
          // errorElement={<EventsError />}
        />
        <Route 
          path="/events/:id" 
          element={<EventDetails />}
          loader={eventDetailsLoader}
        />
      </Route>

      <Route path="organizations" element={<OrganizationsLayout />} errorElement={<OrganizationsError />}>
        <Route 
          index 
          element={<Organizations />} 
          loader={organizationsLoader}
          // errorElement={<OrganizationsError />}
        />
        <Route 
          path="/organizations/:id" 
          element={<OrganizationDetails />}
          loader={organizationDetailsLoader}
        />
      </Route>
    </Route>
  )
)

function App() {
  return (
    <AuthContextProvider>
      <RouterProvider router={router} />
    </AuthContextProvider>
  );
}

export default App