
// i18n.js
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
.use(Backend)
.use(initReactI18next)
.init({
  fallbackLng: 'ar',
  lng: 'ar',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: '/locales/{{lng}}/translation.json',
  },
  react: {
    bindI18n: 'languageChanged',
    useSuspense: true,
  },
});
