import { useTranslation } from "react-i18next"


export default function Faq() {
 const { t } = useTranslation();
  return (
    <div className="faq">
      <h2 style={{marginTop:'2rem', fontSize:'2rem',fontWeight:'400', textAlign:'left', borderBottomStyle:'solid', display:'inline-block'}}>{t('faq')}</h2>
      <div className="question">
        <p><strong>{t('lorem')}</strong></p> <br />
        <p>{t('lorem_ipsum')}</p>
      </div>
      <div className="question">
        <p><strong>{t('lorem')}</strong></p> <br />
        <p>{t('lorem_ipsum')}</p>
      </div>
      <div className="question">
        <p><strong>{t('lorem')}</strong></p> <br />
        <p>{t('lorem_ipsum')}</p>
      </div>
      <div className="question">
        <p><strong>{t('lorem')}</strong></p> <br />
        <p>{t('lorem_ipsum')}</p>
      </div>
      <div className="question">
        <p><strong>{t('lorem')}</strong></p> <br />
        <p>{t('lorem_ipsum')}</p>
      </div>
    </div>
  )
}
