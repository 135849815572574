import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from './context/AuthContext';
import { signUpValidation } from '../pages/rules/AuthRules';
import { useTranslation } from 'react-i18next';

const Signup = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const { createUser } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = signUpValidation(email, password);

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors([]);
    try {
      await createUser(email, password);
      navigate('/account');
    } catch (error) {
      console.error(error.message);

      if (error.code === 'auth/email-already-in-use') {
        setErrors(['Email already exists, please use a different email.']);
      } else {
        setErrors(['Error creating user, please try again.']);
      }
    }
  };

  return (
    <div className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>
      <div className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>
        <h1 className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>{t('signup')}</h1>
        <p>
          {t('account_exists')}{' '}
          <Link to='/signin'>{t('signin')}.</Link>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label style={{display:'inline-block'}}>{t('email')}</label>
          <input onChange={(e) => setEmail(e.target.value)} type='email' />
        </div>
        <div>
          <label style={{display:'inline-block'}}>{t('password')}</label>
          <input onChange={(e) => setPassword(e.target.value)} type='password' />
        </div>
        {errors.length > 0 && (
          <div>
            {errors.map((error, index) => (
              <p key={index} style={{ color: 'red' }}>
                {error}
              </p>
            ))}
          </div>
        )}
        <button className='submit'>{t('signup')}</button>
      </form>
    </div>
  );
};

export default Signup;
