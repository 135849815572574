import {Link} from 'react-router-dom'
import Store from './store/Store'
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { t } = useTranslation();

  return (

      <div className="row">
         <div className="col-lg-6">
          <h1>Outside the Matrix,<br /> and beyond.</h1>
         </div>
          <div className="col-lg-6 index-btns">
          <h2 className="eng">
            <br /> {t('goal')}
          </h2>
            <Link to='events'>
              <button type="button" className="btn btn-outline-dark btn-lg Download-button">
                <i className="fa-regular fa-address-card"></i> {t('events')}
              </button>
            </Link>

            <Link to='organizations'>
              <button type="button" className="btn btn-dark btn-lg Download-button">
                <i className="fa-regular fa-address-card" style={{color:'white'}}></i> {t('organizations')} 
              </button>
            </Link>
          </div>
          <div><Store /></div>
        </div>
  )
}
