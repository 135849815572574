import {Link} from 'react-router-dom'
import Shirts from './products/shirts/Shirts';
import Shoes from './products/shoes/Shoes'
import Book from './Book'


export default function Store() {
    
    return (
    <>

    </>

 )
}