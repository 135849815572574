// authRules.js

export const validateEmail = (email) => {
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  export const validatePassword = (password) => {
    // Password should be at least 6 characters
    return password.length >= 6;
  };
  
  export const signUpValidation = (email, password) => {
    const errors = [];
  
    if (!validateEmail(email)) {
      errors.push("Invalid email address");
    }
  
    if (!validatePassword(password)) {
      errors.push("Password should be at least 6 characters");
    }
  
    return errors;
  };
  
  export const signInValidation = (email, password) => {
    const errors = [];
  
    if (!validateEmail(email)) {
      errors.push("Invalid email address");
    }
  
    if (!validatePassword(password)) {
      errors.push("Password should be at least 6 characters");
    }
  
    return errors;
  };