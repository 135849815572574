import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from './context/AuthContext';
import { useTranslation } from 'react-i18next';

const Account = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const { user, logout } = UserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      console.log('You are logged out')
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>
      <h1 className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>{t('account')}</h1>
      <h2 className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>Email: {user && user.email}</h2>

      <button 
      className='btn btn-outline-dark btn-lg Download-button' 
      onClick={handleLogout}
      style={{margin:'1rem'}}
      >
        {t('logout')}
      </button>
    </div>
  );
};

export default Account;