import { useLoaderData, Link, useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref as refDatabase, get } from 'firebase/database';
import { getStorage, ref as refStorage, getDownloadURL } from 'firebase/storage';
import 'firebase/compat/database';
import firebaseConfig from '../rules/firebaseConfig';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAuth } from '../../auth/context/AuthContext';



const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);

async function getImage(location) {
  if (!storage) {
    throw Error('Storage is not initialized');
  }
  const imageURL = await getDownloadURL(refStorage(storage, `event-img/${location}` + '.jpg'));
  return imageURL;
}

export default function Events() {
  const { t } = useTranslation();
  const events = useLoaderData();
  const [images, setImages] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { user } = UserAuth();

  useEffect(() => {
    const fetchImages = async () => {
      const imageUrls = {};
      for (const event of events) {
        const imageUrl = await getImage(event.id); // Ensure getImage is defined
        imageUrls[event.id] = imageUrl;
      }
      setImages(imageUrls);
    };
    fetchImages();
  }, [events]);


  const navigate = useNavigate();

  const handleJoin = (event, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!user) {
      setShowModal(true);
    } else {
      // Code to join the event
      navigate(event.id.toString());
    }
   };

  function Modal({ show, onClose }) {
    if (!show) {
      return null;
    } else {
      console.log(showModal)
    }

    return (
      <div className="modal show" style={{opacity: 1, visibility: 'visible', zIndex: 1}}>
        <div className="modal-content">
          <span className="close" onClick={onClose}>&times;</span>
          <h2>{t('account_required')} {t('events')}</h2>
          <Link to="/signup">
            <button className="btn btn-outline-dark btn-lg Download-button">{t('signup')} </button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={`row row-cols-1 row-cols-md-2`}>
      {events.map((event) => (
        <div className='col-md-6 col-lg-4' key={event.id}>
          <div className="card">
            <img src={images[event.id]} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">{event.title}</h5>
              <p className="card-context">{event.description}</p>
              <Link to={event.id.toString()} key={event.id}>
              <button 
              type="button" 
              className="btn btn-outline-dark btn-lg Download-button"
              onClick={(e) => handleJoin(event, e)}
              >
              <i className="fa-regular fa-address-card"></i> {t('join_event')}
              </button>
              </Link>
            </div>
          </div>
        </div>
      ))}
      <Modal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
 }

 
// data loader
export const eventsLoader = async () => {
  const eventsRef = refDatabase(database, 'events');
  const snapshot = await get(eventsRef);
 
  if (!snapshot.exists()) {
    throw Error('Could not fetch the list of events');
  }
 
  const eventsData = snapshot.val();
  // Convert the object into an array if needed
  const eventsArray = Object.keys(eventsData).map(key => ({
    id: key,
    ...eventsData[key],
  }));
 
  return eventsArray;
 };

