// FormRules.js
// FormRules.js

export const validateName = (name) => {
  if (!name.trim()) {
    return 'هذا الحقل مطلوب';
  }
  return '';
};

export const validateNationality = validateName;
export const validateDob = validateName;
export const validatePhone = validateName;
export const validateCard = validateName;

export const validateEmail = (email) => {
  if (!email.trim()) {
    return 'هذا الحقل مطلوب';
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return 'Invalid email address';
  }

  return '';
};

export const validateSocialMediaLink = (link) => {
  if (!link.trim()) {
    return 'هذا الحقل مطلوب';
  }

  // Add any specific validation rules for social media link here if needed

  return '';
};

export const validateTalent = (value) => {
  if (!value) {
    return 'اختر موهبة رجاءً';
  }
  return '';
};