import { useLoaderData, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import firebaseConfig from '../rules/firebaseConfig';
import { ref, set, get, push } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { useTranslation } from 'react-i18next';


const app = initializeApp(firebaseConfig);
const db = getDatabase(app);


export default function Contact() {


 const [email, setEmail] = useState("");
 const [message, setMessage] = useState("");
 const [loader, setLoader] = useState(false);
 const [successMessage, setSuccessMessage] = useState("");

 const handleSubmit = (e) => {
  e.preventDefault();
  setLoader(true);
 
  if (!email || !message) {
    alert("Please fill in all fields");
    setLoader(false);
    return;
  }
 
  const newContactRef = push(ref(db, 'contact'));
 
  set(newContactRef, {
    email: email,
    message: message,
  })
  .then(() => {
    setLoader(false);
    setSuccessMessage("Your info were sent, we will contact you as soon as possible");
 
    // Clear the success message after 10 seconds
    setTimeout(() => {
      setSuccessMessage("");
    }, 10000); // 10000 milliseconds = 10 seconds
  })
  .catch((error) => {
    alert(error.message);
    setLoader(false);
  });
 
  setEmail("");
  setMessage("");
 };

 const { t } = useTranslation();

 return (
   <form onSubmit={handleSubmit} className='form'>

     <label>{t('email')}</label>
     <input
       type='email'
       value={email}
       onChange={(e) => setEmail(e.target.value)}
     />

     <label>{t('message')}</label>
     <textarea
       value={message}
       onChange={(e) => setMessage(e.target.value)}
     ></textarea>

     <button className='submit' type="submit">
     {t('submit')}
     </button>
     {successMessage && <h5 
     style={{color: 'white',
     backgroundColor:'green',
     marginTop:'20px',
     borderRadius:'5px',
     padding:'20px'
      }}>{successMessage}</h5>}

   </form>
 );
}