import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"

export default function OrganizationsLayout() {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  return (
    <div className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>
      <h1 style={{borderBottomStyle:'solid', borderColor:'#333', display:'inline'}} className={`${isArabic ? 'rtl-layout' : 'ltr-layout'}`}>{t('organizations')}</h1>
      <Outlet />
    </div>
  )
}
