// LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../images/google-translate-icon.png'

const LanguageSwitcher = () => {
 const { i18n } = useTranslation();

 const toggleLanguage = () => {
  const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
  i18n.changeLanguage(newLanguage);

  setTimeout(() => {
    const bodyElement = document.getElementsByTagName("body")[0];

    if (bodyElement) {
      bodyElement.style.textAlign = newLanguage === 'ar' ? 'right' : 'left';
    }
  }, 100);
};

 return (
   <div style={{ display: 'inline-block', marginLeft: '4px' }}>
     <button onClick={toggleLanguage} style={{ backgroundColor: 'transparent', border: 'none' }}>
       <img
         src={icon}
         alt="Google Translate"
         style={{ width: '48px', height: '48px' }} 
       />
     </button>
   </div>
 );
};

export default LanguageSwitcher;