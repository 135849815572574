import React, { Component } from "react";
import Slider from "react-slick";
import { withTranslation } from "react-i18next";
import { shoesLoader } from './ShoesLoader'
import shoeIcon from '../../../../images/shoe-icon.png'

class Responsive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shoes: [],
      loading: true,
      error: null,
    };
  }

  fetchData = async () => {
    try {
      const shoesData = await shoesLoader();
      this.setState({ shoes: shoesData, loading: false });
    } catch (error) {
      console.error('Error loading shoes:', error);
      this.setState({ error, loading: false });
      // Handle error, show a message to the user, etc.
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { t } = this.props;
    const { shoes, loading, error } = this.state;

    if (loading) {
      // Render a loading spinner or message while data is being fetched
      return <h1>Loading...</h1>;
    }

    if (error) {
      // Render an error message or component
      return <h2>Error: {error.message}</h2>;
    }


    var settings = {
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 6.5,
      slidesToScroll: 2,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1624,
          settings: {
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4.5,
            slidesToScroll: 2,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3.5,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 1,
            initialSlide: 2,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    };

    return (
      <div className="carousel">
        <h1 className="category-heading"><img src={shoeIcon} className="shoe-icon" alt="" /> Shoes </h1>
        <Slider {...settings}>
          {shoes.map(shoe => (
            <div className='col-md-3 col-6 card-box' key={shoe.id}>
              <div className='card'>
                <img className="card-img-top" style={{fontFamily:'Montserrat !important'}} src={shoe.imageUrl} alt={shoe.title} />
                <div className='card-body'>
                  <h5 className='card-title' style={{width:'100%', textAlign:'center', textAlign:'left'}}>{shoe.title}</h5>
                  <p className='card-context' style={{textAlign:'left'}}>{shoe.price}$</p>
                  <button className="btn btn-dark add-to-cart">
                  <i className="fa-solid fa-cart-shopping"></i> Add
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <button type="button" className="btn btn-outline-dark btn-lg category-button">
          <i className="fa-solid fa-arrow-up-right-from-square"></i> View all
        </button>
      </div>
    );
  }
}

export default withTranslation()(Responsive);