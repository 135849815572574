import { useLoaderData, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import firebaseConfig from '../rules/firebaseConfig';
import { ref, set, get, push } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { useTranslation } from 'react-i18next';

import {
  validateName,
  validateEmail,
  validateSocialMediaLink,
  validateNationality,
  validateDob,
  validatePhone,
  validateCard,
  validateTalent
} from '../rules/FormRules';


const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export default function EventDetails() {
  const { t, i18n } = useTranslation();


  const event = useLoaderData();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("male");
  const [nationality, setNationality] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [card, setCard] = useState("");
  const [talent, setTalent] = useState("");
  const [socialMediaCheckbox, setSocialMediaCheckbox] = useState(false);
  const [socialMediaLink, setSocialMediaLink] = useState("");
  const [loader, setLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
  
    newErrors.name = validateName(name);
    newErrors.email = validateEmail(email);
    newErrors.nationality = validateNationality(nationality);
    newErrors.dob = validateDob(dob);
    newErrors.phone = validatePhone(phone);
    newErrors.card = validateCard(card);
    newErrors.talent = validateTalent(talent);
  
    if (socialMediaCheckbox) {
      newErrors.socialMediaLink = validateSocialMediaLink(socialMediaLink);
    }
  
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));

    if (type === 'checkbox') {
      setSocialMediaCheckbox(checked);
    } else {
      if (name === 'name') setName(value);
      if (name === 'email') setEmail(value);
      if (name === 'gender') setGender(value);
      if (name === 'nationality') setNationality(value);
      if (name === 'dob') setDob(value);
      if (name === 'phone') setPhone(value);
      if (name === 'card') setCard(value);
      if (name === 'talent') setTalent(value);
      if (name === 'socialMediaLink') setSocialMediaLink(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoader(true);

      const newContactRef = push(ref(db, event.id + "-event"));

      set(newContactRef, {
        name: name.trim(),
        email: email.trim(),
        gender: gender.trim(),
        nationality: nationality.trim(),
        dob: dob.trim(),
        phone: phone.trim(),
        card: card.trim(),
        talent: talent.trim(),
        socialMediaCheckbox,
        socialMediaLink: socialMediaCheckbox ? socialMediaLink.trim() : '',
      })
        .then(() => {
          setLoader(false);
          setSuccessMessage("تم ارسال معلوماتك بنجاح, سيتم التواصل معك عبر البريد الالكتروني في اقرب وقت ممكن");

          // Clear the success message after 10 seconds
          setTimeout(() => {
            setSuccessMessage("");
          }, 10000); // 10000 milliseconds = 10 seconds
        })
        .catch((error) => {
          alert(error.message);
          setLoader(false);
        });

      setName("");
      setEmail("");
      setGender("");
      setNationality("");
      setDob("");
      setPhone("");
      setCard("");
      setTalent("");
      setSocialMediaCheckbox(false);
      setSocialMediaLink("");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <h2>{event.title}</h2>

      <label>{t('full_name')}</label>
      <input
        value={name}
        name="name"
        onChange={handleInputChange}
        style={{ borderColor: errors.name ? 'red' : '' }}
      />
      {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}

      <label>{t('email')}</label>
      <input
        value={email}
        name="email"
        type="email"
        onChange={handleInputChange}
        style={{ borderColor: errors.email ? 'red' : '' }}
      />
      {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}

      <label>{t('gender')}</label>
<select
  value={gender}
  name="gender"
  onChange={handleInputChange}
  style={{ borderColor: errors.gender ? 'red' : '' }}
>
  <option value="male">{t('male')}</option>
  <option value="female">{t('female')}</option>
</select>

<label>{t('nationality')}</label>
<input
  value={nationality}
  name="nationality"
  onChange={handleInputChange}
  style={{ borderColor: errors.nationality ? 'red' : '' }}
/>
{errors.nationality && <p style={{ color: 'red' }}>{errors.nationality}</p>}

<label>{t('dob')}</label>
<input
  type="date"
  value={dob}
  name="dob"
  onChange={handleInputChange}
  style={{ borderColor: errors.dob ? 'red' : '' }}
/>
{errors.dob && <p style={{ color: 'red' }}>{errors.dob}</p>}

<label>{t('phone_number')}</label>
<input
  value={phone}
  name="phone"
  onChange={handleInputChange}
  style={{ borderColor: errors.phone ? 'red' : '' }}
/>
{errors.phone && <p style={{ color: 'red' }}>{errors.phone}</p>}

<label>{t('card_number')}</label>
<input
  value={card}
  name="card"
  onChange={handleInputChange}
  style={{ borderColor: errors.card ? 'red' : '' }}
/>
{errors.card && <p style={{ color: 'red' }}>{errors.card}</p>}

<label>{t('talent')}</label>
<select
  value={talent}
  name="talent"
  onChange={handleInputChange}
  style={{ borderColor: errors.talent ? 'red' : '' }}
>
  <option value="">{t('choose_talent')}</option>
  <option value="BMX">BMX</option>
  <option value="Parkour">Parkour</option>
  <option value="B-boy">B-boy</option>
  <option value="Singing">Singing</option>
  <option value="Rap">Rap</option>
  <option value="Skateboard">Skateboard</option>
</select>
{errors.talent && <p style={{ color: 'red' }}>{errors.talent}</p>}

<label className="checkbox-container">

  <input
    type="checkbox"
    name="socialMediaCheckbox"
    checked={socialMediaCheckbox}
    onChange={handleInputChange}
    className="checkbox-input"
  />
  <span className="checkbox-label">{t('social_media_checkbox')}</span>
</label>

      {socialMediaCheckbox && (
        <>
          <label>{t('social_media_link')}</label>
          <input
            value={socialMediaLink}
            name="socialMediaLink"
            onChange={handleInputChange}
            style={{ borderColor: errors.socialMediaLink ? 'red' : '' }}
          />
          {errors.socialMediaLink && <p style={{ color: 'red' }}>{errors.socialMediaLink}</p>}
        </>
      )}

      <button className="submit" type="submit">
      {t('submit')}
      </button>
      {successMessage && (
        <h5
          style={{
            color: 'white',
            backgroundColor: 'green',
            marginTop: '20px',
            borderRadius: '5px',
            padding: '20px',
          }}
        >
          {successMessage}
        </h5>
      )}
    </form>
  );
}




// data loader
export const eventDetailsLoader = async ({ params }) => {
 const { id } = params;

 // Fetch the index from the eventMap node
 const indexRef = ref(db, '/eventMap/' + id);
 const indexSnapshot = await get(indexRef);

 if (!indexSnapshot.exists()) {
   throw Error('Could not find that event.')
 }

 const index = indexSnapshot.val();

 // Use the index to fetch the event details from the events node
 const eventRef = ref(db, '/events/' + index);
 const eventSnapshot = await get(eventRef);

 if (!eventSnapshot.exists()) {
   throw Error('Could not find that event.')
 }

 return eventSnapshot.val();
}